import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const Marketing = lazy(() => import('./Marketing'));
const CreateMarketing = lazy(() => import('./CreateMarketing'));
const ViewMarketing = lazy(() => import('./ViewMarketing'));

const marketingRoutes= [
  {
    path: '/marketing',
    component: Marketing,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/marketing/create',
    component: CreateMarketing,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/marketing/:id',
    component: ViewMarketing,
    auth: authRoles.sa,
    exact: true,
  },
];

export default marketingRoutes;
