import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const ManageSuperAdmin = lazy(() => import('./ManageSuperAdmin'));
const CreateSuperAdmin = lazy(() => import('./CreateSuperAdmin'));
const ViewSuperAdmin = lazy(() => import('./ViewSuperAdmin'));

const ManageSuperAdminRoutes = [
  {
    path: '/manage-super-admin',
    component: ManageSuperAdmin,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/manage-super-admin/create',
    component: CreateSuperAdmin,
    auth: authRoles.sa,
    exact: true,
  },
  {
  path: '/manage-super-admin/:id',
  component: ViewSuperAdmin,
  auth: authRoles.sa,
  exact: true,
  } 
];

export default ManageSuperAdminRoutes;
