import { SET_NEW_EVENT_ROOM_DATA } from '../actions/RoomBookingActions';

const initialState = { newEvent: null };

const roomBookingReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_NEW_EVENT_ROOM_DATA: {
      return {
        ...state,
        newEvent: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default roomBookingReducer;
