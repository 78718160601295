import {
  SET_LAYOUT_SETTINGS,
  SET_DEFAULT_LAYOUT_SETTINGS,
  SET_ADMIN_CHOOSE,
  SET_IS_CALL_NOTIFICATION,
} from '../actions/LayoutActions';
import { GullLayoutSettings } from '../../GullLayout/settings';

const initialState = {
  settings: {
    ...GullLayoutSettings,
  },
  defaultSettings: {
    ...GullLayoutSettings,
  },
  adminChoose: '',
  isCallNotification: false,
};

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      };
    case SET_DEFAULT_LAYOUT_SETTINGS:
      return {
        ...state,
        defaultSettings: { ...action.data },
      };
    case SET_ADMIN_CHOOSE:
      return {
        ...state,
        adminChoose: { ...action.data },
      };
    case SET_IS_CALL_NOTIFICATION:
      return {
        ...state,
        isCallNotification: action.data,
      };
    default:
      return { ...state };
  }
};

export default LayoutReducer;
