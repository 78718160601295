import axios from 'app/services/axios';

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const SELECT_ORGANIZATION_SUCCESS = 'SELECT_ORGANIZATION_SUCCESS';

export const selectOrganization = (organizationId) => {
  return (dispatch) => {
    try {
      dispatch({ type: SELECT_ORGANIZATION });
      localStorage.setItem('OrganizationId', organizationId);
      axios.get(`/api/v1/organization/organ/${organizationId}`).then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          dispatch({
            type: SELECT_ORGANIZATION_SUCCESS,
            payload: { data },
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
