import React, { useState, useCallback, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { SimpleCard } from '@gull';
import axios from 'app/services/axios';
import History from '@history';
import { CPFTypesShort, PaymentMethodOptions } from 'app/constants';
import { Button } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';
import swal from 'sweetalert2';
import {
  formatMoneyWithCurrency,
  getSubTotal,
  getSubTotalAmount,
  getBasicSalary,
  checkNumber,
  getPayItemUnit,
  getLeaveAmount,
  getAddition,
  getMonthly,
  formatFixedNumber,
  formatHours,
  getPayItemDefault,
  getBaseSalaryPerHour,
} from '@utils';
import AddPayModal from './AddPayModal';
import { cloneDeep } from 'lodash';

const SALARY_ITEM = ['M1', 'M3'];

const OVERTIME_ITEM = ['M8a', 'M8b', 'M8c', 'M8d'];

const PayrollGenerationConfirm = ({ originPayroll = [], payrollNo, date, payrollId, payslipNo, onDeletePayroll }) => {
  const [payrollData, setPayrollData] = useState([]);
  const [payItems, setPayItems] = useState({});
  const [showAdd, setShowAdd] = useState(false);
  const [dateOfPayment, setDateOfPayment] = useState(date);
  const [isErrorDateOfPayment, setIsErrorDateOfPayment] = useState(false);
  const [tableContainer, setTableContainer] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [showOTDetail, setShowOTDetail] = useState([]);

  const [disableUpdate, setDisableUpdate] = useState(true);

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.payItemId === 'M3') {
      style.backgroundColor = '#EFEFEF';
      style.display = 'none';
    }
    if (row.payItemId === 'TotalWages') {
      style.backgroundColor = '#EEEEFB';
    }
    const isSupItem = showOTDetail.find((x) => row.displayType === `supitem${x.employeeId}`);
    if (isSupItem) {
      style.backgroundColor = '#EFEFEF';
      style.display = isSupItem.show ? '' : 'none';
    }
    return style;
  };

  useEffect(() => {
    const payrollData = originPayroll.map((item) => ({
      id: item.id,
      month: item.month,
      year: item.year,
      periodType: item.periodType,
      periodValue: item.periodValue,
      employeeId: item.employee.id,
      employeeName: item.employee?.name,
      departmentName: item?.employee?.department?.name,
      workingTime: item?.workingTime,
      paymentMethod: item?.paymentMethod,
      paymentMethodExt: item?.paymentMethodExt,
      payTemplateType: item?.payrollItem?.[0]?.type,
      excludeCPFContributions: item?.excludeCPFContributions,
      allowFullEmployerCPF: item?.allowFullEmployerCPF,
      dob: item?.dob,
      ct: item?.ct,
      ordinaryWages: item?.ordinaryWages,
      isTurnOnAttendLog: item?.isTurnOnAttendLog,
      sdlAmount: item?.sdlAmount,
      defaultWorkingHours: item?.defaultWorkingHours,
      originPayItems: getPayItemDefault(
        item?.originPayItems,
        item?.workingTime,
        item?.payrollItem?.[0]?.type,
        false,
        item.employee.id,
        item
      ),
      payItems: item?.payrollItem
        ?.filter((x) => x?.id !== 'M10')
        .map((payroll) => {
          const payRollId = payroll?.id || payroll?.typeId;
          if (payroll?.typeId === 'M9') {
            return {
              ...payroll,
              payItemId: payroll?.payItemId || payroll?.id,
              id: payItems?.typeId || 'M9',
              name: payroll?.name || payroll?.description,
              cPF: payroll?.cPF || payroll?.cPFType,
              category: payroll?.category,
              unit: payroll?.unit,
              iRBACode: payroll?.iRBACode,
            };
          }
          if (payRollId === 'M8') {
            return {
              ...payroll,
              value: getdefaultValueM8(item?.payrollItem),
            };
          }

          if (OVERTIME_ITEM.includes(payRollId)) {
            return {
              ...payroll,
              displayType: `supitem${item.employee.id}`,
              displayCategory: 'overtime',
            };
          }
          return payroll;
        }),
    }));

    const showOT = originPayroll.map((item) => ({
      employeeId: item.employee.id,
      show: false,
    }));
    setShowOTDetail(showOT);
    updatePayRoll(payrollData);
  }, [originPayroll]);

  const updatePayRoll = (newPayroll) => {
    const _newPayroll = newPayroll?.map((item) => {
      if (!item || !Array.isArray(item.payItems)) {
        return [];
      }
      const nw = item.payItems.filter((x) => x?.id === 'M9' && x?.cPF === 'NoWages');
      const m10a = item.payItems.find((x) => x?.id === 'M10A');
      const m4 = item.payItems.find((x) => x?.id === 'M4');
      const m5 = item.payItems.find((x) => x?.id === 'M5');
      const m7 = item.payItems.find((x) => x?.id === 'M7');

      const _item = item.payItems?.filter(
        (x) =>
          !(x?.id === 'M9' && x?.cPF === 'NoWages') &&
          x?.id !== 'M10A' &&
          x?.id !== 'M4' &&
          x?.id !== 'M5' &&
          x?.id !== 'M7' &&
          x?.id !== 'TotalWages'
      );

      const _totalWages = {
        amount: getTotalWagesAmount(newPayroll, currentPage),
        category: 'Total Wages',
        cpf: '',
        defaultAmount: 0,
        id: 'TotalWages',
        name: 'Total Wages',
        type: 'Monthly',
        value: 'Sub total',
      };
      _totalWages.id = 'TotalWages';
      _item.push(_totalWages);
      m10a && _item.push(m10a);
      nw?.length > 0 && _item.push(...nw);
      m7 && _item.push(m7);
      m4 && _item.push(m4);
      m5 && _item.push(m5);

      return {
        ...item,
        payItems: _item,
      };
    });

    setPayrollData(_newPayroll);
    genderTable(_newPayroll);
  };
  const deleteRow = (payItemIdx, payslipIdx) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      if (Array.isArray(newPayroll[currentPage - 1]?.payItems)) {
        newPayroll[currentPage - 1].payItems.splice(payslipIdx, 1);
        setDisableUpdate(false);
        updatePayRoll(newPayroll);
      }
    }
  };

  const addPayItem = (payItem) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      const newPayItem = {
        ...payItem,
        payItemId: payItem?.id,
        name: payItem?.description || payItem?.name,
        cPF: payItem?.cPFType,
        value: payItem?.defaultAmount,
        defaultValue: +payItem?.defaultAmount || 0,
        amount: payItem?.defaultAmount,
        remarks: '',
        id: payItem?.typeId || 'M9',
        iRBACode: payItem?.iRBACode,
        unit: payItem?.unit,
        category: payItem?.category,
      };

      newPayroll[currentPage - 1].payItems = [...newPayroll[currentPage - 1].payItems, newPayItem];
      setDisableUpdate(false);
      updatePayRoll([...newPayroll]);
    }
  };

  const onChangeRemark = (val, pIndex, rowIndex) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      if (Array.isArray(newPayroll[currentPage - 1]?.payItems)) {
        newPayroll[currentPage - 1].payItems[rowIndex].remarks = val;
        updatePayRoll(newPayroll);
      }
    }
  };

  const getdefaultValueM8 = (payItems) => {
    const payItemOverTime = payItems?.filter((item) => {
      if (OVERTIME_ITEM.includes(item?.id)) return true;
    });
    return `${payItemOverTime.map((item) => formatHours(item.value)).join('/')} Hours`;
  };

  const updateDeductionItem = (newPayroll, newDeduction) => {
    if (newDeduction?.find((x) => x.id === 'M4')) {
      const m4Index = newPayroll[currentPage - 1].payItems.findIndex((x) => x.id === 'M4');
      newPayroll[currentPage - 1].payItems[m4Index].amount = formatFixedNumber(
        +newDeduction.find((x) => x.id === 'M4').value
      );
      newPayroll[currentPage - 1].payItems[m4Index].value = formatFixedNumber(
        +newDeduction.find((x) => x.id === 'M4').value
      );
    }
    if (newDeduction?.find((x) => x.id === 'M10A')) {
      const m10AIndex = newPayroll[currentPage - 1].payItems.findIndex((x) => x.id === 'M10A');
      newPayroll[currentPage - 1].payItems[m10AIndex].amount = formatFixedNumber(
        +newDeduction.find((x) => x.id === 'M10A').value
      );
      newPayroll[currentPage - 1].payItems[m10AIndex].value = formatFixedNumber(
        +newDeduction.find((x) => x.id === 'M10A').value
      );
    }
    if (newDeduction?.find((x) => x.id === 'M6')) {
      const m6Index = newPayroll[currentPage - 1].payItems.findIndex((x) => x.id === 'M6');
      if (newPayroll[currentPage - 1].payItems[m6Index]?.amount) {
        newPayroll[currentPage - 1].payItems[m6Index].amount = formatFixedNumber(
          +newDeduction.find((x) => x.id === 'M6').value
        );
      }
      if (newPayroll[currentPage - 1].payItems[m6Index]?.value) {
        newPayroll[currentPage - 1].payItems[m6Index].value = formatFixedNumber(
          +newDeduction.find((x) => x.id === 'M6').value
        );
      }
    }
    setDisableUpdate(true);
    updatePayRoll([...newPayroll]);
  };

  const calculateDeduction = (employeeId, aw, ow, newPayroll) => {
    axios
      .post('/api/v1/employee/calculator-deduction', {
        employeeId,
        aw,
        ow,
        paymentPeriod: {
          month: originPayroll?.[0]?.month,
          year: originPayroll?.[0]?.year,
          periodType: originPayroll?.[0]?.periodType,
          periodValue: originPayroll?.[0]?.periodValue,
        },
      })
      .then(({ data }) => {
        const newDeduction = data?.data?.payItem || [];
        updateDeductionItem(newPayroll, newDeduction);
      })
      .catch((error) => {
        setDisableUpdate(true);
        updatePayRoll([...newPayroll]);
      });
  };

  const getOrdinaryWages = (newPayroll, page) => {
    const m3 =
      getBasicSalary(
        newPayroll[page - 1].payItems,
        newPayroll[page - 1].workingTime,
        newPayroll[page - 1].payTemplateType,
        newPayroll[page - 1].payItems.find((x) => x.id === 'M3')?.isTurnOnAttendLog
      ) || 0;
    const m8a = +newPayroll[page - 1].payItems.find((x) => x.id === 'M8a')?.amount || 0;
    const m8b = +newPayroll[page - 1].payItems.find((x) => x.id === 'M8b')?.amount || 0;
    const m8c = +newPayroll[page - 1].payItems.find((x) => x.id === 'M8c')?.amount || 0;
    const m8d = +newPayroll[page - 1].payItems.find((x) => x.id === 'M8d')?.amount || 0;

    const absence = getAbsence(newPayroll, page);
    const m2a = getTotalLateless(newPayroll, page);
    const m2b = getTotalEarly(newPayroll, page);
    const ow = +newPayroll[page - 1].payItems
      .filter((x) => x.id === 'M9' && x.cPFType === 'OrdinaryWages')
      .reduce((curr, next) => {
        if (next.category === 'Deduction') curr = curr - Number(next.value || 0);
        else curr = curr + Number(next.value || 0);
        return curr;
      }, 0);
    return formatFixedNumber(m3) + formatFixedNumber(m8a) + formatFixedNumber(m8b) + formatFixedNumber(m8c) + formatFixedNumber(m8d) + formatFixedNumber(ow) - formatFixedNumber(absence) - formatFixedNumber(m2a) - formatFixedNumber(m2b);
  };

  const getAdditionalWages = (newPayroll, page) => {
    return formatFixedNumber(+newPayroll[page - 1].payItems
      .filter((x) => (x.id === 'M9' && x.cPFType === 'AdditionalWages') || x.id === 'L4')
      .reduce((curr, next) => {
        let value = next.value || 0;
        if (next.id === 'L4') {
          value = next.amount;
        }
        if (next.category === 'Deduction') curr = curr - Number(value);
        else curr = curr + Number(value);
        return curr;
      }, 0));
  };

  const getAbsence = (newPayroll, page) => {
    const { payItems, workingTime } = newPayroll[page - 1];
    const absences = payItems.filter((item) => item.id === 'M2' && item.name === 'Absence');
    const totalAbsences = absences.reduce((prev, cur) => prev + getLeaveAmount(cur, payItems?.[0], workingTime), 0);
    return totalAbsences || 0;
  };

  const getLateless = (payMonthly, workingTime, payItems, defaultWorkingHours = 8) => {
    const lateless = payItems.find((item) => item.id === 'M2a' && item.name === 'Total Lateness');
    // let monthlySalary = payMonthly?.fixedAmount > 0 ? payMonthly.fixedAmount : payMonthly?.defaultAmount || 0;
    const hourSalary = getBaseSalaryPerHour(payMonthly);
    return hourSalary * +(lateless?.value || 0);
    //
    // if (payMonthly.type === 'Daily') {
    //   return (payMonthly?.amount / (defaultWorkingHours || 8)) * +lateless.value;
    // }
    //
    // if (payMonthly.type === 'Hourly') {
    //   return monthlySalary * +lateless.value;
    // } else {
    //   const totalHours = workingTime?.workingDatesInRange?.totalHous || 0;
    //   const totalLateless = totalHours ? (monthlySalary * +lateless.value) / totalHours : 0;
    //   return totalLateless || 0;
    // }
  };

  const getTotalLateless = (newPayroll, page) => {
    const { workingTime, originPayItems, payItems } = newPayroll[page - 1];
    const payMonthly = originPayItems?.[0];
    return getLateless(payMonthly, workingTime, payItems, newPayroll?.defaultWorkingHours);
  };

  const getEarly = (payMonthly, workingTime, payItems, defaultWorkingHours = 8) => {
    const early = payItems.find((item) => item.id === 'M2b' && item.name === 'Total Early Out');
    const hourSalary = getBaseSalaryPerHour(payMonthly);
    return hourSalary * +(early?.value || 0);

    // if (payMonthly.type === 'Daily') {
    //   return (payMonthly?.amount / (defaultWorkingHours || 8)) * +early.value;
    // }
    //
    // if (payMonthly.type === 'Hourly') {
    //   return monthlySalary * +early.value;
    // } else {
    //   const totalHours = workingTime?.workingDatesInRange?.totalHous || 0;
    //   const totalLateless = totalHours ? (monthlySalary * +early.value) / totalHours : 0;
    //   return totalLateless || 0;
    // }
  };

  const getTotalEarly = (newPayroll, page) => {
    const { originPayItems, workingTime, payItems } = newPayroll[page - 1];
    const payMonthly = originPayItems?.[0];
    return getEarly(payMonthly, workingTime, payItems, newPayroll?.defaultWorkingHours);
  };

  const getTotalWagesAmount = (newPayroll, page) => {
    return getOrdinaryWages(newPayroll, page) + getAdditionalWages(newPayroll, page);
  };

  const calcTotalWage = (employeeId, newPayroll, rowIndex) => {
    // const currentItem = newPayroll[currentPage - 1].payItems[rowIndex];
    // if (['M1', 'M2', 'M3', 'M8a', 'M8b', 'M8c', 'M8d', 'M9'].includes(currentItem?.id)) {
    calculateDeduction(
      employeeId,
      getAdditionalWages(newPayroll, currentPage),
      getOrdinaryWages(newPayroll, currentPage),
      newPayroll
    );
    // }
  };

  const onChangeValue = (val, pIndex, rowIndex, category) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      if (Array.isArray(newPayroll[currentPage - 1]?.payItems)) {
        newPayroll[currentPage - 1].payItems[rowIndex].value = val;

        if (SALARY_ITEM.includes(category)) {
          const defaultAmount = newPayroll[currentPage - 1].payItems[rowIndex].defaultAmount;
          newPayroll[currentPage - 1].payItems[rowIndex].amount = formatFixedNumber(+val * defaultAmount);
        } else if (OVERTIME_ITEM.includes(category)) {
          const type = newPayroll[currentPage - 1].payItems[rowIndex].id;
          let rate = 0;
          switch (type) {
            case 'M8a':
              rate = newPayroll[currentPage - 1]?.workingTime?.workingDatesInRange?.overTimeRateHourly || 0;
              break;
            case 'M8b':
              rate = newPayroll[currentPage - 1]?.workingTime?.workingDatesInRange?.overTimeOffDayRateHourly || 0;
              break;
            case 'M8c':
              rate = newPayroll[currentPage - 1]?.workingTime?.workingDatesInRange?.overTimeRestDayRateHourly || 0;
              break;
            case 'M8d':
              rate = newPayroll[currentPage - 1]?.workingTime?.workingDatesInRange?.overTimeHolidayRateHourly || 0;
              break;
            default:
              break;
          }

          newPayroll[currentPage - 1].payItems[rowIndex].amount = formatFixedNumber(+val * rate);

          const payItemOverTime = newPayroll[currentPage - 1].payItems?.filter((item) => {
            if (OVERTIME_ITEM.includes(item?.id)) return true;
          });
          let totalAmount = payItemOverTime.reduce((sum, item) => sum + (item?.amount ? item?.amount : 0), 0);
          totalAmount = formatFixedNumber(totalAmount);
          const consolidatedString = payItemOverTime.map((item) => formatHours(item.value)).join('/');
          const m8Item = newPayroll[currentPage - 1].payItems.find((item) => item.id === 'M8');
          if (m8Item) {
            m8Item.value = `${consolidatedString} Hours`;
            m8Item.amount = totalAmount;
          }
        } else if (['L4'].includes(category)) {
          //leave encashment
          const item = newPayroll[currentPage - 1].payItems[rowIndex];
          const { payItems } = newPayroll[currentPage - 1];
          const hourSalary = getBaseSalaryPerHour(payItems[0]);
          item.amount = hourSalary * +val || 0;
        } else {
          newPayroll[currentPage - 1].payItems[rowIndex].amount = formatFixedNumber(+val);
        }

        newPayroll[currentPage - 1].payItems[rowIndex].defaultValue = -1;
        const currentItem = newPayroll[currentPage - 1].payItems[rowIndex];
        if (['M1', 'M2', 'M3', 'M8a', 'M8b', 'M8c', 'M8d', 'M9', 'L4'].includes(currentItem?.id)) {
          setDisableUpdate(false);
        }
        updatePayRoll(newPayroll);
      }
    }
  };

  const handleUpdate = (pIndex, rowIndex, category) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      if (Array.isArray(newPayroll[currentPage - 1]?.payItems)) {
        calcTotalWage(newPayroll[currentPage - 1].employeeId, newPayroll, rowIndex);
      }
    }
  };

  const handleChangePayMethod = (val, rowIndex) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      newPayroll[currentPage - 1].paymentMethod = val.target.value;
      updatePayRoll(newPayroll);
    }
  };

  const onChangePaymentMethodExt = (val, rowIndex) => {
    const newPayroll = cloneDeep(payrollData);
    if (Array.isArray(newPayroll) && newPayroll.length > 0) {
      newPayroll[currentPage - 1].paymentMethodExt = val;
      updatePayRoll(newPayroll);
    }
  };

  const isDisabled = (isTurnOnAttendLog, name) => {
    return false; //(isTurnOnAttendLog && ['Unpaid Leave'].includes(name)) || (!isTurnOnAttendLog && name === 'Paid Leave');
  };

  const genderTable = useCallback(
    (tableData) => {
      if (!Array.isArray(tableData) || tableData.length === 0) {
        return;
      }

      const filterData = tableData.filter((item, index) => index === currentPage - 1);

      const _expandedPayrollData = filterData.flatMap((item, index) => {
        if (!item || !Array.isArray(item.payItems)) {
          return [];
        }

        return item.payItems.map((payItem, payItemIndex) => ({
          employeeId: item.employeeId,
          employee: payItemIndex === 0 ? item.employeeName : null, // Only set for the first payItem
          department: payItemIndex === 0 ? item.departmentName : null, // Only set for the first payItem
          workingTime: item.workingTime,
          payTemplateType: item.payTemplateType,
          dob: item.dob,
          payItems: item.payItems,
          currentPayItems: [payItem],
          paymentMethod: payItemIndex === 0 ? item.paymentMethod : null,
          paymentMethodExt: payItemIndex === 0 ? item.paymentMethodExt : null,
          excludeCPFContributions: item.excludeCPFContributions,
          allowFullEmployerCPF: item.allowFullEmployerCPF,
          ct: item.ct,
          displayType: item.displayType,
          ordinaryWages: item.ordinaryWages,
          isTurnOnAttendLog: item.isTurnOnAttendLog,
          show: false,
          payItemId: payItem.id,
          defaultWorkingHours: item.defaultWorkingHours,
          originPayItems: item.originPayItems,
        }));
      });

      const nw = _expandedPayrollData.filter(
        (x) => x?.currentPayItems?.[0]?.id === 'M9' && x?.currentPayItems?.[0]?.cPF === 'NoWages'
      );
      const m10a = _expandedPayrollData.find((x) => x?.currentPayItems?.[0]?.id === 'M10A');
      const m4 = _expandedPayrollData.find((x) => x?.currentPayItems?.[0]?.id === 'M4');
      const m5 = _expandedPayrollData.find((x) => x?.currentPayItems?.[0]?.id === 'M5');
      const m7 = _expandedPayrollData.find((x) => x?.currentPayItems?.[0]?.id === 'M7');

      const expandedPayrollData = _expandedPayrollData?.filter(
        (x) =>
          !(x?.currentPayItems?.[0]?.id === 'M9' && x?.currentPayItems?.[0].cPF === 'NoWages') &&
          x?.currentPayItems?.[0]?.id !== 'M10A' &&
          x?.currentPayItems?.[0]?.id !== 'M4' &&
          x?.currentPayItems?.[0]?.id !== 'M5' &&
          x?.currentPayItems?.[0]?.id !== 'M7' &&
          x?.currentPayItems?.[0]?.id !== 'TotalWages'
      );

      const totalWages = _expandedPayrollData.find((x) => x?.currentPayItems?.[0]?.id === 'TotalWages');
      totalWages.currentPayItems = [
        {
          amount: getTotalWagesAmount(tableData, currentPage),
          category: 'Total Wages',
          cpf: '',
          defaultAmount: 0,
          id: 'TotalWages',
          name: 'Total Wages',
          type: 'Monthly',
          value: 'Sub total',
        },
      ];
      totalWages.payItemId = 'TotalWages';
      expandedPayrollData.push(totalWages);
      m10a && expandedPayrollData.push(m10a);
      nw?.length > 0 && expandedPayrollData.push(...nw);
      m7 && expandedPayrollData.push(m7);
      m4 && expandedPayrollData.push(m4);
      m5 && expandedPayrollData.push(m5);

      const tableList = expandedPayrollData.map(
        (
          {
            id,
            employeeId,
            employee,
            department,
            paymentMethod,
            paymentMethodExt,
            paymentMethodValue,
            payItems,
            currentPayItems,
            workingTime,
            payTemplateType,
            dob,
            excludeCPFContributions,
            allowFullEmployerCPF,
            ct,
            ordinaryWages,
            isTurnOnAttendLog,
            sdlAmount,
            payItemId,
            defaultWorkingHours,
            originPayItems,
          },
          ind
        ) => {
          const showOT = showOTDetail?.find((x) => x.employeeId === employeeId);
          return {
            id: ind,
            payItemId,
            employee: (
              <div
                key={`${employeeId}-employee-${ind}`}
                className={`d-flex align-items-center`}
                style={{ height: '40px' }}
              >
                {employee}
              </div>
            ),
            department: (
              <div
                key={`${employeeId}-department-${ind}`}
                className={`d-flex align-items-center`}
                style={{ height: '40px' }}
              >
                {department}
              </div>
            ),
            paymentMethod:
              ind === 0 ? (
                <div
                  key={`${employeeId}-paymentmethod-${ind}`}
                  className={`d-flex align-items-center`}
                  style={{ height: '40px' }}
                >
                  <select
                    id="payType"
                    key={`${employeeId}-payment_select-${ind}`}
                    className="form-control"
                    name="payType"
                    style={{ marginRight: '10px' }}
                    value={paymentMethod}
                    onChange={(value) => handleChangePayMethod(value, ind)}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {PaymentMethodOptions.map(({ value, label }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  {paymentMethod === 'giro' && (
                    <div>
                      <input
                        className="form-control"
                        placeholder="Bank name - number"
                        value={paymentMethodExt}
                        onChange={(e) => onChangePaymentMethodExt(e.target.value, ind)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              ),
            paymentMethodValue,
            displayType: currentPayItems?.[0]?.displayType ?? '',
            payItem: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <div
                    key={`${employeeId}-payItem_div-${pIndex}`}
                    className={`fw-bold text-12 d-flex align-items-center ${
                      pi.displayType === `supitem${employeeId}` ? 'sup-item-class' : ''
                    }`}
                    style={{
                      height: '40px',
                      backgroundColor:
                        pi.displayType === `supitem${employeeId}`
                          ? '#EFEFEF'
                          : pi?.payItemId === 'TotalWages'
                          ? '#EEEEFB'
                          : '',
                    }}
                  >
                    {pi.displayType === `supitem${employeeId}` && (
                      <>
                        <img
                          key={`${employeeId}-payItem_img-${pIndex}`}
                          src="/assets/images/payroll/arrow-turn-down-right.svg"
                          alt=""
                          className="icon-class"
                          style={{ paddingRight: '5px' }}
                        />
                        <span key={`${employeeId}-payItem_span-${pIndex}`} style={{ fontWeight: 'bold' }}>
                          {pi.name}
                        </span>
                      </>
                    )}
                    {pi.displayType !== `supitem${employeeId}` && pi.name}
                  </div>
                ))}
              </>
            ),
            remarks: (
              <>
                {currentPayItems.map((pi, pIndex) =>
                  ['TotalWages'].includes(pi?.id) ? (
                    <div
                      key={`${employeeId}-value_3-${pIndex}`}
                      style={{ height: '40px' }}
                      className="d-flex align-items-center fw-bold "
                    ></div>
                  ) : (
                    <input
                      key={`${employeeId}-remarks-${pIndex}`}
                      className="form-control form-control-small"
                      placeholder="Enter details (optional)"
                      value={pi?.remarks}
                      onChange={(e) => onChangeRemark(e.target.value, pIndex, ind)}
                    />
                  )
                )}
              </>
            ),
            category: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <div
                    key={`${employeeId}-category-${pIndex}`}
                    className="text-12 fw-bold d-flex align-items-center"
                    style={{ height: '40px' }}
                  >
                    {pi.category}
                  </div>
                ))}
              </>
            ),
            cPF: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <div
                    key={`${employeeId}-cpf-${pIndex}`}
                    className="text-12 fw-bold d-flex align-items-center justify-content-center"
                    style={{ height: '40px' }}
                  >
                    {pi?.id === 'M9' ? CPFTypesShort?.[pi.cPF] || pi.cpf : pi.cpf}
                  </div>
                ))}
              </>
            ),
            value: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <>
                    {['L4'].includes(pi?.id) && (
                      <div key={`${employeeId}-value_1-${pIndex}`} className="d-flex align-items-center">
                        <input
                          key={`${employeeId}-value_input-${pIndex}`}
                          className="form-control"
                          defaultValue={pi?.defaultValue > -1 ? pi?.defaultValue : pi?.value}
                          type="number"
                          onChange={(e) => onChangeValue(e.target.value, pIndex, ind, pi.id)}
                        />
                        <div key={`${employeeId}-value_unit-${pIndex}`} className="ps-2">
                          {getPayItemUnit(pi)}
                        </div>
                      </div>
                    )}
                    {['M1', 'M2', 'M2a', 'M2b', 'M8a', 'M8b', 'M8c', 'M8d', 'M9'].includes(pi?.id) && (
                      <div key={`${employeeId}-value_1-${pIndex}`} className="d-flex align-items-center">
                        <input
                          key={`${employeeId}-value_input-${pIndex}`}
                          className="form-control"
                          defaultValue={pi?.value}
                          type="number"
                          min="0"
                          // disabled={isDisabled(isTurnOnAttendLog, pi.name)}
                          onChange={(e) => onChangeValue(e.target.value, pIndex, ind, pi.id)}
                        />
                        <div key={`${employeeId}-value_unit-${pIndex}`} className="ps-2">
                          {getPayItemUnit(pi)}
                        </div>
                      </div>
                    )}
                    {['M3', 'M4', 'M5', 'M6', 'M7', 'M10'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-value_2-${pIndex}`}
                        style={{ height: '40px' }}
                        className="d-flex align-items-center"
                      />
                    )}
                    {['M8'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-value_3-${pIndex}`}
                        style={{ height: '40px' }}
                        className="d-flex align-items-center"
                      >
                        {pi?.value}
                      </div>
                    )}

                    {['TotalWages'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-value_3-${pIndex}`}
                        style={{ height: '40px' }}
                        className="d-flex align-items-center fw-bold "
                      >
                        {pi?.value}
                      </div>
                    )}

                    {/* {['M4', 'M5', 'M6', 'M7'].includes(pi?.id) && (
                    <div style={{ height: '40px' }} className="d-flex align-items-center mb-2">
                      {pi?.value}
                    </div>
                  )} */}
                  </>
                ))}
              </>
            ),
            amount: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <div key={`${employeeId}-amount-${pIndex}`} className="d-flex align-items-center justify-content-end">
                    {!['M1', 'M2', 'M2a', 'M2b', 'M3', 'M10', 'M9'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_1-${pIndex}`}
                        className="text-12 fw-bold d-flex align-center"
                        style={{ height: '40px' }}
                      >
                        {pi?.category?.toLowerCase() === 'deduction' && <span>-</span>}
                        {formatMoneyWithCurrency(pi.amount)}
                      </div>
                    )}
                    {['M1'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_2-${pIndex}`}
                        className="text-12 fw-bold d-flex align-center"
                        style={{ height: '40px' }}
                      >
                        {pi?.category?.toLowerCase() === 'deduction' && <span>-</span>}
                        {formatMoneyWithCurrency(getMonthly(pi, workingTime, payTemplateType))}
                      </div>
                    )}
                    {['M9'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_3-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        {pi?.category?.toLowerCase() === 'deduction' && <span>-</span>}
                        {formatMoneyWithCurrency(getAddition(pi, payItems?.[0]))}
                      </div>
                    )}
                    {['M2'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_4-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        {isDisabled(isTurnOnAttendLog, pi.name) && <>Nil</>}
                        {!isDisabled(isTurnOnAttendLog, pi.name) && pi?.category?.toLowerCase() === 'deduction' && (
                          <span>-</span>
                        )}
                        {!isDisabled(isTurnOnAttendLog, pi.name) &&
                          formatMoneyWithCurrency(
                            getLeaveAmount(pi, originPayItems?.[0], workingTime, defaultWorkingHours)
                          )}
                      </div>
                    )}
                    {['M2a'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_4-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        -
                        {formatMoneyWithCurrency(
                          getLateless(originPayItems?.[0], workingTime, payItems, defaultWorkingHours)
                        )}
                      </div>
                    )}
                    {['M2b'].includes(pi?.id) && (
                      <div
                        key={`${employeeId}-amount_4-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        -
                        {formatMoneyWithCurrency(
                          getEarly(originPayItems?.[0], workingTime, payItems, defaultWorkingHours)
                        )}
                      </div>
                    )}
                    {pi?.id === 'M3' && (
                      <div
                        key={`${employeeId}-amount_5-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        {pi?.category?.toLowerCase() === 'deduction' && <span>-</span>}
                        {formatMoneyWithCurrency(
                          getBasicSalary(payItems, workingTime, payTemplateType, isTurnOnAttendLog)
                        )}
                      </div>
                    )}
                    {pi?.id === 'M10' && (
                      <div
                        key={`${employeeId}-amount_6-${pIndex}`}
                        className="text-12 fw-bold d-flex align-items-center"
                        style={{ height: '40px' }}
                      >
                        {pi?.category?.toLowerCase() === 'deduction' && <span>-</span>}
                        {pi?.amount ||
                          formatMoneyWithCurrency(
                            getSubTotal(
                              payItems,
                              workingTime,
                              payTemplateType,
                              dob,
                              excludeCPFContributions,
                              allowFullEmployerCPF,
                              ct,
                              ordinaryWages,
                              isTurnOnAttendLog,
                              sdlAmount
                            )?.cpf
                          )}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ),
            action: (
              <>
                {currentPayItems.map((pi, pIndex) => (
                  <div key={pi.id}>
                    {pi.id === 'M9' && (
                      <img
                        key={`${employeeId}-action_img_1-${pIndex}`}
                        style={{ display: `${pi.category === 'Salary' ? 'none' : ''}` }}
                        src="/assets/images/icons/delete.svg"
                        className="logo ms-2 btn-icon-only"
                        alt="delete"
                        onClick={() => {
                          swal
                            .fire({
                              title: '',
                              text: '',
                              type: 'question',
                              customClass: 'delete',
                              html: renderToStaticMarkup(
                                <>
                                  <div className="my-5">
                                    <img src="/assets/images/icons/sweet-delete.svg" alt="delete" />
                                  </div>
                                  <div className="text-16 fw-normal">
                                    Deleting pay item {pi.name}
                                    <br />
                                    Are you sure you want to proceed?
                                  </div>
                                </>
                              ),
                              showCancelButton: true,
                              confirmButtonText: 'Delete',
                              cancelButtonText: 'Cancel',
                            })
                            .then((result) => {
                              if (result.value) {
                                deleteRow(pIndex, ind);
                                swal.fire('Deleted!', 'Your pay item has been deleted.', 'success');
                              }
                            });
                        }}
                      />
                    )}
                    {pi.id === 'M8' && (
                      <img
                        key={`${employeeId}-action_img_2-${pIndex}`}
                        style={{
                          display: `${pi.category === 'Salary' ? 'none' : ''}`,
                          marginTop: '6px',
                          marginLeft: '2px',
                        }}
                        src={!showOT?.show ? '/assets/images/icons/down.svg' : '/assets/images/icons/up.svg'}
                        className="logo ms-2 btn-icon-only"
                        alt="expand"
                        onClick={() => {
                          let otDetail = showOTDetail?.filter((x) => x.employeeId !== employeeId) || [];
                          setShowOTDetail([...otDetail, { employeeId: employeeId, show: !showOT?.show }]);
                        }}
                      />
                    )}
                    {pi.id === 'M10A' &&
                      (disableUpdate ? (
                        <Button
                          className="btn btn-rounded"
                          variant="custom"
                          style={{ height: '30px', width: '80px', backgroundColor: '#E5E5E5', color: '#999999' }}
                          disabled
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-rounded btn-primary"
                          style={{ height: '30px', width: '80px' }}
                          onClick={() => {
                            handleUpdate(pIndex, ind, pi.id);
                          }}
                        >
                          Update
                        </Button>
                      ))}
                  </div>
                ))}
              </>
            ),
            index: ind + 1,
          };
        }
      );

      const total = getSubTotalAmount(filterData)?.total ?? 0;

      const totalRow = {
        employee: '',
        department: '',
        payItem: (
          <>
            <div className="d-flex align-items-center" style={{ height: '40px' }}>
              <Button
                className="btn btn-rounded btn-primary"
                onClick={() => {
                  setShowAdd(true);
                }}
              >
                <img src={'/assets/images/icons/plus.svg'} className="me-2" alt="plus" />
                Add More Pay Items
              </Button>
            </div>
          </>
        ),
        value: (
          <div className="fw-bolder d-flex align-items-center" style={{ height: '40px' }}>
            Total
          </div>
        ),
        amount: (
          <div className="fw-bolder d-flex align-items-center justify-content-end" style={{ height: '40px' }}>
            {formatMoneyWithCurrency(total)}
          </div>
        ),
        isTotalRow: true,
      };

      const tableListWithTotal = [...tableList, totalRow];

      const tableContainerData = tableData.map(({ employee }, ind) => ({
        employee: (
          <>
            <BootstrapTable
              keyField="id"
              wrapperClasses="payroll-inside"
              data={tableListWithTotal}
              columns={sortableColumn}
              bootstrap4
              noDataIndication={'Table is empty'}
              headerWrapperClasses="foo"
              pagination={false}
              rowStyle={rowStyle}
            />
          </>
        ),
        index: ind + 1,
      }));

      setTableContainer(tableContainerData);
    },
    [showOTDetail, currentPage, payrollData]
  );

  const getPayItem = () => {
    axios.get(`/api/v1/payroll/pay-items`).then(({ data }) => {
      setPayItems(data?.data);
    });
  };

  const getTotalValue = (
    item,
    time,
    templateType,
    dob,
    excludeCPFContributions,
    allowFullEmployerCPF,
    ct,
    ordinaryWages,
    isTurnOnAttendLog,
    sdlAmount
  ) => {
    const subTotalData = getSubTotal(
      item,
      time,
      templateType,
      dob,
      excludeCPFContributions,
      allowFullEmployerCPF,
      ct,
      ordinaryWages,
      isTurnOnAttendLog,
      sdlAmount
    );
    const subTotal = subTotalData?.total;
    const subTotalCpf = subTotalData?.cpf;

    return [
      {
        id: 'M10',
        value: !isFinite(subTotalCpf) ? 0 : subTotalCpf,
        amount: !isFinite(subTotalCpf) ? 0 : subTotalCpf,
      },
      {
        id: 'M11',
        value: !isFinite(subTotal) ? 0 : subTotal,
        amount: !isFinite(subTotal) ? 0 : subTotal,
      },
    ];
  };

  const onConfirm = () => {
    if (!dateOfPayment) setIsErrorDateOfPayment(true);

    const params = {
      dateOfPayment: dateOfPayment,
      payslips: payrollData.map((payroll, index) => ({
        id: payroll?.id,
        employeeId: payroll?.employeeId,
        paymentMethod: payroll?.paymentMethod,
        paymentMethodExt: payroll?.paymentMethodExt,
        salary: {
          value: !isFinite(payroll.payItems[0].value)
            ? 0
            : checkNumber(payroll.payItems[0].value)
            ? Number(payroll.payItems[0].value)
            : payroll.payItems[0].value,
          amount: !isFinite(payroll.payItems[0].amount)
            ? 0
            : checkNumber(payroll.payItems[0].amount)
            ? +getMonthly(payroll.payItems[0], payroll.workingTime, payroll.payTemplateType).toFixed(2)
            : payroll.payItems[0].amount,
          remarks: payroll?.payItems[0].remarks,
          id: payroll?.id,
        },
        payslipPayItems: payroll?.payItems
          .filter((item) => item?.id === 'M9' || item?.typeId === 'M9')
          .map((data) => {
            return {
              payItemId: data?.payItemId || data?.id,
              description: data?.description || data?.name,
              category: data?.category,
              cPFType: data?.cPFType || data?.cPF,
              value: Number(data?.value) || 0,
              amount: getAddition(data, payroll?.payItems?.[0]) || 0,
              unit: data?.unit,
              iRBACode: data?.iRBACode,
            };
          }),
        payrollItem: [
          ...(payroll?.payItems || []).map((item) => {
            if (item?.id === 'M2') {
              const amount = getLeaveAmount(item, payroll?.payItems?.[0], payroll?.workingTime);
              return {
                ...item,
                value: Number(item?.value) || 0,
                amount: !isFinite(amount) ? 0 : checkNumber(amount) ? Number(amount) : amount,
              };
            }
            if (item?.id === 'M3') {
              return {
                ...item,
                value: Number(item?.value) || 0,
                amount: parseInt(
                  getBasicSalary(
                    payroll?.payItems,
                    payroll?.workingTime,
                    payroll?.payTemplateType,
                    payroll?.isTurnOnAttendLog
                  )
                ),
              };
            }

            if (item?.id === 'M9') {
              return {
                value: Number(item?.value) || 0,
                amount: getAddition(item, payroll?.payItems?.[0]) || 0,
                id: item?.payItemId,
                typeId: 'M9',
                description: item?.name,
                cPFType: item?.cPF,
                iRBACode: item?.iRBACode,
                category: item?.category,
                unit: item?.unit,
              };
            }
            if (item?.id === 'M1') {
              return {
                ...item,
                value: !isFinite(item.value) ? 0 : checkNumber(item.value) ? Number(item.value) : item.value,
                amount: !isFinite(item.amount)
                  ? 0
                  : +getMonthly(item, payroll.workingTime, payroll.payTemplateType).toFixed(2),
              };
            }

            return {
              ...item,
              value: !isFinite(item.value) ? 0 : checkNumber(item.value) ? Number(item.value) : item.value,
              amount: !isFinite(item.amount) ? 0 : checkNumber(item.amount) ? Number(item.amount) : item.amount,
            };
          }),
          ...getTotalValue(
            payroll?.payItems,
            payroll?.workingTime,
            payroll?.payTemplateType,
            payroll?.dob,
            payroll?.excludeCPFContributions,
            payroll?.allowFullEmployerCPF,
            payroll?.ct,
            payroll?.ordinaryWages,
            payroll?.isTurnOnAttendLog,
            payroll?.sdlAmount
          ),
        ],
        employeeCPF: getSubTotal(
          payroll?.payItems,
          payroll?.workingTime,
          payroll?.payTemplateType,
          payroll?.dob,
          payroll?.excludeCPFContributions,
          payroll?.allowFullEmployerCPF,
          payroll?.ct,
          payroll?.ordinaryWages,
          payroll?.isTurnOnAttendLog,
          payroll?.sdlAmount
        )?.cpf,
        employerCPF: getSubTotal(
          payroll?.payItems,
          payroll?.workingTime,
          payroll?.payTemplateType,
          payroll?.dob,
          payroll?.excludeCPFContributions,
          payroll?.allowFullEmployerCPF,
          payroll?.ct,
          payroll?.ordinaryWages,
          payroll?.isTurnOnAttendLog,
          payroll?.sdlAmount
        )?.m10b,
        grossSalary: getSubTotal(
          payroll?.payItems,
          payroll?.workingTime,
          payroll?.payTemplateType,
          payroll?.dob,
          payroll?.excludeCPFContributions,
          payroll?.allowFullEmployerCPF,
          payroll?.ct,
          payroll?.ordinaryWages,
          payroll?.isTurnOnAttendLog,
          payroll?.sdlAmount
        )?.grossSalary,
        ow: getOrdinaryWages(payrollData, index + 1),
        aw: getAdditionalWages(payrollData, index + 1),
      })),
    };
    axios.put(`/api/v1/payroll/${payrollId}`, params).then((res) => {
      if (res.status === 200) {
        swal.fire({
          icon: 'success',
          title: 'Payroll has been updated',
          showConfirmButton: false,
          timer: 1500,
        });
        History.push('/payroll/list');
      }
    });
  };

  useEffect(() => {
    // getPayroll();
    getPayItem();
  }, []);

  useEffect(() => {
    genderTable(payrollData);
  }, [payrollData, genderTable, currentPage]);

  const sortableColumn = [
    {
      dataField: 'employee',
      text: 'Employee',
      headerStyle: { width: '150px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'department',
      text: 'Department',
      headerStyle: { width: '100px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'paymentMethod',
      text: 'Payment Method',
      headerStyle: { width: '200px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'payItem',
      text: 'Pay Item',
      headerStyle: { width: '250px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      headerStyle: { width: '200px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'category',
      text: 'Category',
      headerStyle: { width: '150px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'cPF',
      text: 'CPF',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '100px', padding: '2px', height: '20px' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'value',
      text: 'Value',
      headerStyle: { width: '150px', padding: '2px', height: '20px' },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      //headerStyle: { width: '120px' , padding: '2px', height:'20px'},
      headerStyle: { width: '120px', padding: '2px', height: '20px', textAlign: 'right' },
      style: { textAlign: 'right' }, // This aligns cell content to the center
    },
    {
      dataField: 'action',
      text: 'Action',
      align: 'center',
      headerAlign: 'center',
      style: { textAlign: 'center' },
      headerStyle: { width: '100px', padding: '2px', height: '20px' },
    },
  ];

  const sortableColumnContainer = [
    {
      dataField: 'employee',
      headerStyle: { width: '150px', padding: '0px', height: '0px', overflow: 'hidden', visibility: 'hidden' },
    },
  ];

  const paginationOptions = {
    sizePerPage: 1,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page);
    },
  };

  return (
    <div className="payroll-wrap">
      <div className="text-30 fw-bold mb-3">{'Edit Payslip #' + payslipNo}</div>
      <SimpleCard>
        <ToolkitProvider striped keyField="id" data={tableContainer} columns={sortableColumnContainer} search>
          {(props) => (
            <>
              <div className="row">
                <div className="text-20 text-black custom-card-title col-md-12 mb-4">Payslip #{payslipNo}</div>
              </div>
              <BootstrapTable
                wrapperClasses="payroll-container"
                headerClasses="hidden-table-header"
                keyField="id"
                {...props.baseProps}
                bootstrap4
                noDataIndication={'Table is empty'}
                headerWrapperClasses="hidden-body-border"
                bordered={false}
                pagination={paginationFactory(paginationOptions)}
              />
            </>
          )}
        </ToolkitProvider>
      </SimpleCard>
      <div className="row mt-4 mb-4">
        <div className="col-md-12">
          <div className="form-actions d-flex">
            {/* <div>
              <Button className="btn btn-rounded btn-primary mt-2 btn-delete" type="button" onClick={onDeletePayroll}>
                Delete Payroll
              </Button>
            </div> */}
            <div className="d-flex gap-5">
              <Button
                className="btn btn-rounded btn-primary mt-2 btn-cancel"
                type="button"
                variant="outline-primary"
                onClick={() => History.push('/payroll/list')}
              >
                Back
              </Button>
              <Button className="btn btn-rounded btn-primary mt-2 btn-save" onClick={onConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showAdd && (
        <AddPayModal open={showAdd} closeDialog={() => setShowAdd(false)} payItems={payItems} addPayItem={addPayItem} />
      )}
    </div>
  );
};

export default PayrollGenerationConfirm;
