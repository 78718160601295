import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, SimpleCard } from '@gull';
import axios from 'app/services/axios';
import Calender from 'app/components/calender/Calender';
import { Button, Modal } from 'react-bootstrap';
import { getLinkFile } from '@utils';
import { Views } from 'react-big-calendar';
import moment from 'moment';
import dayjs from 'dayjs';
import History from '@history';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';
import HeaderPage from 'app/components/HeaderPage';

const getWeekRange = (date) => {
  const startOfWeek = moment(date).startOf('week').toDate();
  const endOfWeek = moment(date).endOf('week').toDate();
  return { startOfWeek, endOfWeek };
};

const RoomBookingScheduleEdit = () => {
  const { id: bookingId } = useParams();
  const [employeeList, setEmployeeList] = useState([]);
  const [branches, setBranches] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [roomBookings, setRoomBookings] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [room, setRoom] = useState();
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isView = query.get('type') === 'view';
  const selectBoxRef = useRef(null);
  const [filter, setFilter] = useState({
    employeeId: '',
    branchOutletId: '',
    roomId: '',
  });

  const findEmployee = () => employeeList.find((item) => item.id === filter.employeeId);
  const findBranch = () => branches.find((item) => item.id === filter.branchOutletId);
  const findRoom = () => rooms.find((item) => item.id === filter.roomId);
  const isExistEmpty = view === Views.WEEK && !filter.roomId;

  const getEmployees = async () => {
    await axios.get('/api/v1/employee').then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        const employees = data?.filter((el) => el.isActive === true);
        setEmployeeList(employees);
      }
    });
  };

  const getBranches = async () => {
    await axios.get(`/api/v1/organization/branch`).then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        setBranches(data);
      }
    });
  };

  const getRooms = async (branchId) => {
    await axios.get(`/api/v1/room?branchOutletId=` + branchId).then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        setRooms(data);
      }
    });
  };

  useEffect(() => {
    getEmployees();
    getBranches();
    // getRooms();
  }, []);

  useEffect(() => {
    axios.get('/api/v1/room-booking/' + bookingId).then((res) => {
      const { data: { data: data } = {} } = res;
      const bookingDate = moment(data.bookingDate).toDate();
      setDate(bookingDate);
      setFilter({
        employeeId: data.employeeBookedId,
        branchOutletId: data.branchOutletId,
        roomId: data.roomId,
      });
      getRooms(data.branchOutletId);
      handleRefreshData(view, bookingDate, {
        employeeId: data.employeeBookedId,
        branchOutletId: data.branchOutletId,
        roomId: data.roomId,
      });
    });
  }, []);

  const getCalenders = async (params) => {
    await axios.get(`/api/v1/room-booking`, { params }).then((res) => {
      const { data, statusCode } = res?.data;
      if (statusCode === 200) {
        setRoomBookings(data);
        setShowCalender(true);
      }
    });
  };

  const handleFilter = (fieldName, value) => {
    const newFilter = { ...filter };
    newFilter[fieldName] = value;
    setFilter({ ...newFilter });
  };

  const handleShowRoomInfo = () => {
    const roomId = selectBoxRef.current ? selectBoxRef.current.value : filter.roomId;
    axios.get('/api/v1/room/' + roomId).then(({ data }) => {
      setRoom(data.data);
      setOpenModal(true);
    });
  };

  const handleRefreshData = (view, date, params = null) => {
    setView(view);
    setDate(date);
    const filterParams = params || filter;
    const roomId = (selectBoxRef.current ? selectBoxRef.current.value : '') || filterParams.roomId;
    setFilter({ ...filterParams, roomId });
    if (view === Views.DAY) {
      getCalenders({
        employeeId: filterParams.employeeId,
        branchOutletId: filterParams.branchOutletId,
        from: dayjs(date).format('YYYY-MM-DD'),
        to: dayjs(date).format('YYYY-MM-DD'),
      });
    } else {
      const dateRange = getWeekRange(date);
      getCalenders({
        employeeId: filterParams.employeeId,
        branchOutletId: filterParams.branchOutletId,
        roomId,
        from: dayjs(dateRange.startOfWeek).format('YYYY-MM-DD'),
        to: dayjs(dateRange.endOfWeek).format('YYYY-MM-DD'),
      });
    }
  };

  const handleBack = () => {
    History.push('/room-booking/room-booking-schedule');
  };

  const bookRoom = () => {
    setShowCalender(true);
    handleRefreshData(view, date);
  };

  const updateRoom = (room) => {
    if (selectBoxRef.current) {
      selectBoxRef.current.value = room.id;
    }
  };

  const getViewName = () => {
    return isView ? 'View Room Booking' : 'Edit Room Booking';
  };

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Room Booking', path: '/staff-room-booking' },
          {
            name: getViewName(),
          },
        ]}
      />
      <HeaderPage title={getViewName()} />
      <SimpleCard>
        <div className="room-booking-calender-filter">
          <div className="row">
            <div className="col-12 col-xl-3">
              <div className="form-group mb-4">
                <label htmlFor="branchOutletId">Branch/Outlet*</label>
                <select
                  id="branchOutletId"
                  className="form-control"
                  name="branchOutletId"
                  value={filter?.branchOutletId}
                  onChange={(e) => {
                    const branchId = e.currentTarget.value;
                    getRooms(branchId);
                    handleFilter('branchOutletId', branchId);
                  }}
                >
                  <option value="">Select an branch/outlet</option>
                  {branches.map((branch) => (
                    <option value={branch.id} key={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-xl-3">
              <div className="form-group mb-4">
                <label htmlFor="roomId">Room*</label>
                <div className="d-flex">
                  <select
                    id="roomId"
                    className="form-control"
                    name="roomId"
                    ref={selectBoxRef}
                    disabled={view === Views.DAY}
                    value={filter.roomId}
                    onChange={(e) => view === Views.WEEK && handleFilter('roomId', e.currentTarget.value)}
                  >
                    <option value="">Select an room</option>
                    {rooms.map((room) => (
                      <option value={room.id} key={room.id}>
                        {room.name}
                      </option>
                    ))}
                  </select>
                  {view !== Views.DAY && selectBoxRef.current && selectBoxRef.current.value && (
                    <img
                      onClick={handleShowRoomInfo}
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      alt="information"
                      src="/assets/images/icons/room-info.svg"
                    />
                  )}
                </div>
              </div>
            </div>
            {showCalender && (
              <div className="col-12 col-xl-3">
                <div className="mt-24 d-flex">
                  <Button
                    className="btn-rounded"
                    onClick={bookRoom}
                    style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
                    disabled={isExistEmpty}
                  >
                    Search
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {showCalender && (
            <Calender
              view={view}
              setView={setView}
              curDate={date}
              refreshDataCalendar={handleRefreshData}
              rooms={rooms}
              employee={findEmployee()}
              roomBookings={roomBookings}
              branch={findBranch()}
              room={findRoom()}
              handleChangeRoom={updateRoom}
              url={'/room-booking/room-booking-schedule'}
              isEdit
            />
          )}
        </div>
      </SimpleCard>
      <div
        className="button-wrapper d-flex justify-content-end"
        style={{ marginTop: '40px', marginBottom: '10px', gap: '40px' }}
      >
        <Button
          className="btn-rounded btn-outline-primary"
          onClick={handleBack}
          style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
        >
          Back
        </Button>
        {!showCalender && (
          <Button
            className="btn-rounded"
            onClick={bookRoom}
            style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
            disabled={isExistEmpty}
          >
            Book Room
          </Button>
        )}
      </div>
      <Modal show={openModal}>
        <div style={{ padding: '20px' }}>
          <img alt="room" src={getLinkFile(room?.image)} />
          <div style={{ marginTop: '20px', marginBottom: '40px', fontSize: '20px', fontWeight: 600 }}>{room?.name}</div>
          <div className="d-flex" style={{ gap: '10px' }}>
            {room?.amenitySelected.map((item) => (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: 'rgba(236, 255, 236, 1)',
                  border: '1px solid rgba(99, 205, 98, 1)',
                  borderRadius: '5px',
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div style={{ marginTop: '40px' }} className="d-flex justify-content-end">
            <Button
              onClick={() => setOpenModal(false)}
              style={{ borderRadius: '20px', padding: '5px 22px', width: '160px' }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RoomBookingScheduleEdit;
