import React, { useState } from 'react';
import { Breadcrumb } from '@gull';
import { SimpleCard } from '@gull';
import axios from 'app/services/axios';
import History from '@history';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';
import swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import PayrollGenerationEditTable from './PayrollGenerationEditTableV2';
import { useLocation } from 'react-router-dom';

const getDefaultPayrollItems = (data = []) => {
  const payItemToShow = data.filter((item) => !['M10', 'M11'].includes(item?.id));
  return [
    ...payItemToShow,
    {
      name: 'CPF contribution',
      cpf: '',
      category: 'Deduction',
      amount: 0,
      defaultAmount: 0,
      value: 0,
      id: 'M10',
    },
  ];
};

const PayrollGenerationEdit = () => {
  const [payrollData, setPayrollData] = useState();
  const [payrollNo, setPayrollNo] = useState('');
  const { id } = useParams();
  const [dateOfPayment, setDateOfPayment] = useState();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const payslipNo = query.get('payslipNo');

  useEffect(() => {
    getPayroll();
  }, []);

  const getPayroll = async () => {
    const res =
      (await axios.get(`/api/v1/payroll/${id}`, {
        params: {
          payslipNo,
        },
      })) || {};
    const resData = res?.data?.data;
    if (res?.data?.statusCode === 200) {
      setPayrollNo(resData?.payrollNo);
      setDateOfPayment(new Date(resData?.dateOfPayment).toISOString());
      const payrollData = (resData?.payslips || []).map((payslip) => {
        return {
          ...payslip,
          payrollItem: getDefaultPayrollItems(payslip?.payRollItem),
          month: resData.month,
          year: resData.year,
          periodType: resData.periodType,
          periodValue: resData.periodValue,
          defaultWorkingHours: resData?.defaultWorkSchedule?.defaultWorkingHours
        };
      });
      setPayrollData(payrollData);
    }
  };

  const onDelete = () => {
    swal
      .fire({
        title: '',
        text: '',
        type: 'question',
        customClass: 'delete',
        html: renderToStaticMarkup(
          <>
            <div className="my-5">
              <img alt="Delete Payroll" src="/assets/images/icons/sweet-delete.svg" />
            </div>
            <div className="text-16 fw-normal mb-2">
              Deleting Payroll #{payrollNo}
              <br />
              Are you sure you want to delete?
            </div>
          </>
        ),
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      })
      .then((result) => {
        if (result.value) {
          axios.delete(`/api/v1/payroll/${id}`).then((res) => {
            if (res.status === 200) {
              swal.fire({
                icon: 'success',
                title: 'Payroll has been deleted',
                showConfirmButton: false,
                timer: 1500,
              });
              History.push('/payroll/list');
            }
          });
        }
      });
  };

  return (
    <div className="payroll-wrap">
      <Breadcrumb
        routeSegments={[
          { name: 'Payroll', path: '/payroll/list' },
          { name: 'Payroll List', path: '/payroll/list' },
          { name: 'Summary of Payroll #' + payrollNo, path: `/payroll/${id}` },
          { name: 'Edit Payslip #' + payslipNo, path: '/payroll/' + id + '/edit' },
        ]}
      />

      {payrollData && (
        <PayrollGenerationEditTable
          originPayroll={payrollData}
          date={dateOfPayment}
          payrollNo={payrollNo}
          payslipNo={payslipNo}
          payrollId={id}
          onDeletePayroll={onDelete}
        />
      )}
    </div>
  );
};

export default PayrollGenerationEdit;
