import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import EventPopover from './EventPopover';

const CustomEvent = ({
  url,
  event,
  updateRoom,
  handleChangeDate,
  clearEvent,
  view,
  employee,
  rooms,
  refreshData,
  allEvents,
  setEvents,
  isEdit,
  resetOldEvents,
}) => {
  const ref = useRef();
  const [showPopover, setShowPopover] = useState(false);

  const handleCloseCustomEvent = (isUpdated = false) => {
    setShowPopover(false);
    if (!event.event.isUpdate) {
      clearEvent();
    } else if (isUpdated) {
      refreshData();
    }
  };

  const handleClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    if (event.event.isNew || event?.event?.isShow) {
      if (ref.current) {
        ref.current.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popoverItem = (event) => {
    return (
      <Popover
        id={`popover-basic popover-basic${event.event.id}`}
        title={event.title}
        style={{ maxWidth: '448px', height: '187px', padding: '20px', gap: '20px', zIndex: 999 }}
        onClick={(e) => e.stopPropagation()}
      >
        <EventPopover
          url={url}
          refreshData={refreshData}
          employee={employee}
          rooms={rooms}
          handleChangeDate={handleChangeDate}
          view={view}
          event={event}
          updateRoom={updateRoom}
          closePopover={handleCloseCustomEvent}
          allEvents={allEvents}
          setEvents={setEvents}
          isEdit={isEdit}
        />
      </Popover>
    );
  };

  const editItem = (event) => {
    let isShow = showPopover;
    let clsName = 'edit-event';
    if ((isEdit && event.event.isNew) || (!isEdit && event.event.isEdit)) {
      isShow = false;
      clsName = 'new-event';
    }
    return (
      <OverlayTrigger
        show={isShow}
        onToggle={(show) => setShowPopover(show)}
        id={event.event.id}
        trigger={['click']}
        placement="auto"
        rootClose
        onEnter={() => resetOldEvents(event)}
        children={ref}
        overlay={popoverItem(event)}
      >
        <div
          ref={ref}
          id={`new-event-${event.event.id}-overlayTrigger`}
          className={clsName}
          style={{ width: '100%', height: '100%' }}
        >
          <div>{event.event.employee.name}</div>
          <div style={{ fontSize: '80%', marginTop: '5px' }}>
            {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
          </div>
        </div>
      </OverlayTrigger>
    );
  };

  const newItem = (event) => {
    let isShow = showPopover;
    if (!isEdit && event.event.isNew) {
      isShow = true;
    }
    return (
      <OverlayTrigger
        show={isShow}
        onToggle={(show) => setShowPopover(show)}
        id={event.event.id}
        trigger={['click']}
        placement="auto"
        rootClose
        children={ref}
        overlay={popoverItem(event)}
      >
        <div
          ref={ref}
          id={`new-event-${event.event.id}-overlayTrigger`}
          className="new-event"
          style={{ width: '100%', height: '100%' }}
        >
          <div>{event.event.employee.name}</div>
          <div style={{ fontSize: '80%', marginTop: '5px' }}>
            {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
          </div>
        </div>
      </OverlayTrigger>
    );
  };
  return (
    <>
      {event.event.isEdit ? (
        editItem(event)
      ) : event.event.isNew ? (
        newItem(event)
      ) : (
        <>
          <div className="old-event" style={{ width: '100%', height: '100%' }}>
            <div>{event.event.employee.name}</div>
            <div style={{ fontSize: '80%', marginTop: '5px' }}>
              {moment(event.event.start).format('hh:mm A')} - {moment(event.event.end).format('hh:mm A')}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomEvent;
