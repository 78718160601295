import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const generateTimeSlots = (startTime, endTime, intervalMinutes, isDisplayDuration) => {
  const times = [];
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  let elapsedMinutes = 0;
  if (!isDisplayDuration) {
    times.push({ value: start.clone(), label: `${start.format('h:mm A')}` });
  }
  while (start.isBefore(end)) {
    const formattedTime = start.format('h:mm A');
    let duration;
    if (elapsedMinutes < 60) {
      duration = `${elapsedMinutes} mins`;
    } else {
      const hours = Math.floor(elapsedMinutes / 60);
      const minutes = elapsedMinutes % 60;
      if (minutes === 0) {
        duration = `${hours} hr${hours > 1 ? 's' : ''}`;
      } else {
        duration = `${hours}.${(minutes / 60).toString().split('.')[1]} hrs`;
      }
    }
    times.push({
      value: start.clone(),
      label: `${formattedTime}` + (isDisplayDuration ? ` (${duration})` : ''),
      duration,
    });
    start.add(intervalMinutes, 'minutes');
    elapsedMinutes += intervalMinutes;
  }

  return times.slice(1);
};

const TimeSlotEvent = ({ event, handleUpdateTimeSlot }) => {
  const timeOptions = generateTimeSlots(moment(event.event.start).format('HH:mm'), '23:59', 15, true);
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState(timeOptions);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const now = moment();
    const start = moment(event.event.start);

    let startTime;
    if (start.isSame(now, 'day') && start.isSame(now, 'month') && start.isSame(now, 'year')) {
      startTime = now
        .clone()
        .startOf('minute')
        .add(15 - (now.minute() % 15), 'minutes');
    } else {
      startTime = start.clone().startOf('day');
    }
    const timeOptionsStart = generateTimeSlots(startTime.format('HH:mm'), '23:59', 15, false);
    setStartTimes(timeOptionsStart);
  }, []);

  const handleToggle = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdown);
    }
  };

  const selectStartTime = (value) => {
    const times = value.split(' ');
    const hourMinutes = times[0].split(':');
    const isAm = times[1] === 'AM';
    let hour = +hourMinutes[0];
    let minute = +hourMinutes[1];
    if (isAm) {
      if (hour === 12) hour = 0;
    } else {
      hour = hour + 12;
    }
    const startTimes = moment(event.event.start).hour(hour).minute(minute);
    const newEvent = {
      ...event,
      event: { ...event.event, start: startTimes.toDate(), end: startTimes.clone().add(1, 'hours').toDate() },
    };
    const endTimeOptions = generateTimeSlots(moment(startTimes.toDate()).format('HH:mm'), '23:59', 15, true);
    setEndTimes(endTimeOptions);
    handleUpdateTimeSlot(newEvent);
  };
  const handleClick = (value) => {
    const times = value.split(' ');
    const time = times[1] === 'mins' ? +times[0] / 60 : times[0];
    const newEvent = {
      ...event,
      event: { ...event.event, end: moment(event.event.start).add(time, 'hours').toDate() },
    };
    handleUpdateTimeSlot(newEvent);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Dropdown show={openDropdown === 'dropdown1'} onToggle={() => handleToggle('dropdown1')}>
        <Dropdown.Toggle as="span" className="toggle-hidden cursor-pointer profile">
          {moment(event.event.start).format('hh:mm A')}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-slottime"
          style={{ maxHeight: '406px', overflowY: 'auto', paddingTop: 0, width: '384px' }}
        >
          {startTimes.map((item) => (
            <Dropdown.Item className="time-option" onClick={() => selectStartTime(item.label)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {' - '}
      <Dropdown show={openDropdown === 'dropdown2'} onToggle={() => handleToggle('dropdown2')}>
        <Dropdown.Toggle as="span" className="toggle-hidden cursor-pointer profile">
          {moment(event.event.end).format('hh:mm A')}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-slottime"
          style={{ maxHeight: '406px', overflowY: 'auto', paddingTop: 0, width: '384px' }}
        >
          {endTimes.map((item) => (
            <Dropdown.Item className="time-option" onClick={() => handleClick(item.duration)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TimeSlotEvent;
