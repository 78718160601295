import React, { useLayoutEffect, useRef, useState } from 'react';
import axios from 'app/services/axios';
import CustomTimeGutterHeader from './CustomTimeGutterHeader';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import DateTime from 'app/components/DatePicker';
import TimeSlotEvent from './TimeSlotEvent';
import { getDateToIsoString, getTimeIsoString } from '@utils';
import Swal from 'sweetalert2';
import { message } from 'antd';
import { Views } from 'react-big-calendar';
import EventRoom from './EventRoom';
import { renderToStaticMarkup } from 'react-dom/server';
import History from '@history';
import { isArray } from 'lodash';

const EventPopover = ({
  url,
  event,
  updateRoom,
  handleChangeDate,
  employee,
  closePopover,
  view,
  rooms,
  allEvents,
  setEvents,
  isEdit,
}) => {
  const [messageApi] = message.useMessage();

  const currentRef = useRef();

  const [showPicker, setShowPicker] = useState(false);

  const [eventItem, setEventItem] = useState(event);

  const dateTimeRef = useRef();

  useLayoutEffect(() => {
    if (dateTimeRef?.current) {
      const childDiv = dateTimeRef?.current.querySelector('.rdtStatic');
      if (childDiv) {
        if (childDiv?.classList?.contains('rdtStatic')) {
          childDiv.classList.remove('rdtStatic');
        }
      }
    }
  }, [showPicker]);

  const handleChangeTimeEvent = (v, event) => {
    setShowPicker(false);
    if (v.startOf('day').isSame(moment(eventItem.event.start).startOf('day'))) return;
    const date = v.set({ hour: eventItem.event.start.getHours() });
    const newEvent = {
      ...eventItem,
      event: {
        ...eventItem.event,
        start: date.toDate(),
        isEdit: false,
        isNew: true,
        end: date
          .clone()
          .hour(
            eventItem.event.end.getHours() + eventItem.event.end.getMinutes() / 60
            // 'hours'
          )
          .toDate(),
      },
    };
    setEventItem(newEvent);
    handleChangeDate(newEvent);
  };

  const handleUpdateTimeSlot = (event) => {
    setEventItem(event);
    const currentEvent = allEvents?.find((item) => (item.isNew || item.isEdit) && item.id === event.event.id);
    if (currentEvent) {
      currentEvent.start = event.event.start;
      currentEvent.end = event.event.end;
      if (currentEvent?.isEdit) {
        currentEvent.isEdit = false;
        currentEvent.isNew = true;
      }
      setEvents([...allEvents?.filter((item) => item.id !== event.event.id), currentEvent]);
    }
  };

  const handleUpdateRoom = (room) => {
    closePopover(true);
    const newEvent = {
      ...eventItem,
      event: { ...eventItem.event, room },
    };
    setEventItem(newEvent);
    updateRoom(room);
  };

  const handleSubmit = () => {
    const roomId = eventItem?.event?.roomId || eventItem.event.room?.id;
    const body = {
      bookingDate: getDateToIsoString(moment(eventItem.event.start), moment(eventItem.event.start).format('HH:mm')),
      bookingTimeFrom: getTimeIsoString(moment(eventItem.event.start).format('HH:mm')),
      bookingTimeFromOrigin: moment(eventItem.event.start).format('HH:mm'),
      bookingTimeTo: getTimeIsoString(moment(eventItem.event.end).format('HH:mm')),
      bookingTimeToOrigin: moment(eventItem.event.end).format('HH:mm'),
      bookingDateFrom: getDateToIsoString(moment(eventItem.event.start), moment(eventItem.event.start).format('HH:mm')),
      bookingDateTo: getDateToIsoString(moment(eventItem.event.end), moment(eventItem.event.end).format('HH:mm')),
      employeeBookedId: eventItem.event.employee.id,
      branchOutletId: eventItem.event.branch.id,
      roomId,
    };

    if (body.bookingTimeTo === '00:00') body.bookingTimeTo = '24:00';
    if (isEdit) {
      handleEditRoom(body);
    } else {
      handleCreateRoom(body);
    }
  };

  const handleEditRoom = (body) => {
    axios
      .put('/api/v1/room-booking/' + eventItem.event.id, { ...body })
      .then(() => {
        Swal.fire('Update Booking room!', 'Done', 'success').then(() => {
          History.push(url);
        });
      })
      .catch((error) => {
        const errors = error?.response?.data?.message || '';
        const message = errors ? errors.toString() : '';
        Swal.fire('Error!', message, 'error');
      });
  };

  const handleCreateRoom = (body) => {
    axios
      .post(`/api/v1/room-booking`, body)
      .then((res) => {
        if (res?.data?.statusCode === 201) {
          closePopover(true);
          Swal.fire({
            customClass: 'alert-without-cancel',
            confirmButtonText: 'Back to room Booking',
            html: renderToStaticMarkup(
              <>
                <div className="my-5">
                  <img src="/assets/images/icons/check-with-circle.svg" alt="check" />
                </div>
                <div className="text-16 fw-normal mb-2">You have successfully booked a room!</div>
              </>
            ),
            onClose: () => History.push(url),
          }).then((result) => {
            if (result.value) {
              History.push(url);
            }
          });
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.message || '';
        const message = errors ? errors.toString() : '';
        Swal.fire('Error!', message, 'error');
      });
  };

  const isValidDate = (currentDate) => {
    const today = moment().subtract('1', 'days');
    const dateTimeEvent = moment(eventItem.event.start).format('HH:mm');
    const dateTimeToday = today.format('HH:mm');
    if (currentDate.startOf('day').isSame(moment().startOf('day'))) {
      return dateTimeEvent > dateTimeToday;
    }
    return currentDate.isAfter(moment().subtract('1', 'days'));
  };

  return (
    <div className="event-popover-custom" ref={currentRef}>
      <div>
        <div className="d-flex justify-content-between date-time-wrapper">
          <div
            onClick={() => setShowPicker(view === Views.WEEK)}
            style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
          >
            <img alt="location" src="/assets/images/icons/clock.svg" />
            {!showPicker && (
              <div style={{ marginLeft: '20px' }}>
                <div className="date-time">{moment(eventItem.event.start).format('dddd, DD MMM')}</div>
                <CustomTimeGutterHeader employee={employee} />
              </div>
            )}
          </div>
          {showPicker && (
            <>
              <div ref={dateTimeRef}>
                <DateTime
                  value={eventItem.event.start}
                  name="start"
                  isHideInput={true}
                  isValidDate={isValidDate}
                  onChange={(v) => handleChangeTimeEvent(v)}
                />
              </div>
              <div>
                <div className="date-time">{moment(eventItem.event.start).format('dddd, DD MMM')}</div>
                <CustomTimeGutterHeader />
              </div>
            </>
          )}
          <div className="date-time time">
            <TimeSlotEvent event={eventItem} handleUpdateTimeSlot={handleUpdateTimeSlot} />
          </div>
        </div>
      </div>
      <div className="location d-flex">
        <img alt="location" src="/assets/images/icons/location.svg" />
        <div className="room">
          {view === Views.WEEK ? (
            <>{event.event.roomName ? event.event?.roomName : event.event.room?.name}</>
          ) : (
            <EventRoom updateRoom={handleUpdateRoom} event={event} rooms={rooms} />
          )}
        </div>
      </div>
      <div className="button-wrapper">
        <Button className="btn-rounded btn-outline-primary" onClick={() => closePopover(false)}>
          Cancel
        </Button>
        <Button className="btn-rounded" onClick={handleSubmit}>
          Book room
        </Button>
      </div>
    </div>
  );
};
export default EventPopover;
